import React, { useState, useEffect } from 'react'

import TodoList from "./components/TodoList"

import { TodoItemType } from "./types"

export const Home = () => {
    const [page, setPage] = useState("todo")
    // const [todoList, setTodoList] = useState([])
    // const [doneList, setDoneList] = useState([])
    const [unifiedList, setUnifiedList] = useState<Array<TodoItemType>>([])
    const [newTodoText, setNewTodoText] = useState<string>("")
    const todoList = unifiedList.filter((todo) => todo.status === null || todo.status === 0 )
    const doneList = unifiedList.filter((todo) => todo.status === 1 )

    const [loadingStatus, setLoadingStatus] = useState(true)

    const csrf_meta = document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement | {content: ""}
    
    useEffect(() => {
        fetch("todos").then((response) => {
            return response.json()
        }).then((json) => {
            setUnifiedList(json)
            setLoadingStatus(false)
        })
    }, [loadingStatus])

    const submitNewTodo = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        fetch("todos", {
            method: "POST",
            headers: {
                "X-CSRF-Token": csrf_meta.content
            },
            body: formData
        }).then((response) => {
            return response.json()
        }).then((todo) => {
            setUnifiedList([...unifiedList, todo])
            setNewTodoText("")
        }) 
    }

    const removeTodo = (id) => {
        fetch(`todos/${id}`, {
            method: "DELETE",
            headers: {
                "X-CSRF-Token": csrf_meta.content,
                "Content-Type": "application/json"
            },
        }).then(() => {
            setUnifiedList(unifiedList.filter((t) => t.id !== id))
        })
    }

    const updateTodo = ({id, status}) => {
        const todoUpdate = {status: status};
        fetch(`todos/${id}`, {
            method: "PUT",
            headers: {
                "X-CSRF-Token": csrf_meta.content,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(todoUpdate)
        }).then((response) => {
            return response.json()
        }).then((todo) => {
            const newList = unifiedList.filter((t) => t.id !== id)
            newList.push(todo)
            setUnifiedList(newList)
        })
    }

    return (
        <div className="flex flex-col items-center" data-controller="home">
            <div className=" border border-gray-300">
                <TodoList updateTodo={updateTodo} removeTodo={removeTodo} setPage={setPage} todo={loadingStatus ? [] : todoList} done={doneList} page={page} />
                <form className="w-full max-w-sm bg-yellow-200 p-4" onSubmit={submitNewTodo}>
                    <input type="hidden" name="authenticity_token" value={csrf_meta.content}></input>
                    <input type="hidden" name="status" value={0}></input>
                    <input type="text" name="name" className="h-12 w-full" value={newTodoText} onChange={(e) => setNewTodoText(e.target.value)}></input>
                    <button type='submit' className="hidden"></button>
                </form>
            </div>
        </div>
        )
}

export default Home 