import React, { useState, useEffect } from "react"
import TodoItem from "../TodoItem"
import classNames from "classnames"

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Tab from "./Tab"

import { TodoItemType } from "../../types"


const reorder = (list: Array<any>, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };



export const TodoList = ({todo, updateTodo, removeTodo, done, page, setPage }: {todo: TodoItemType[], updateTodo: Function, removeTodo: Function, done: TodoItemType[], page: string, setPage: Function }) => {
    const items = page === "todo" ? todo : done
    const [list, setList] = useState(items)

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            list,
            result.source.index,
            result.destination.index
        );

        setList(items);
    }

    useEffect(() => {
        setList(items)
    }, [items])

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <div className="flex flex-col w-full items-center list-none">
                <div id="container" className={classNames("max-w-sm", "w-full", { "bg-green-300": page === "done", "bg-blue-300": page === "todo"})}>
                    <div className="flex justify-between w-full">
                        <Tab color="bg-blue-300" onClick={() => setPage("todo")} label="Todo" selected={ page === "todo" } unselectClasses="border-r border-b border-gray-400"/>
                        <Tab color="bg-green-300" onClick={() => setPage("done")} label="Done" selected={ page === "done" } unselectClasses="border-l border-b border-gray-400" />
                    </div>
                    <div className="flex w-full justify-center">
                        <Droppable droppableId="droppable" className="w-full flex">
                            {(provided, snapshot) => {
                                return (
                                    <div {...provided.droppableProps} ref={provided.innerRef} class="w-full mx-8">
                                        {list.map((todo, i) => {
                                            console.log("todo.dbId", todo.id)
                                            return (
                                                <Draggable key={i} draggableId={todo.name + i} index={i}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            >
                                                            <TodoItem label={todo.name} status={todo.status} id={todo.id} updateTodo={updateTodo} onDelete={() => removeTodo(todo.id)} />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            )}
                                            )}
                                            {provided.placeholder}
                                    </div>
                                )
                            }}
                        </Droppable>
                    </div>
                </div>
            </div>

        </DragDropContext>
    )
}

export default TodoList