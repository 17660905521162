// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

import React from 'react'
import ReactDOM from 'react-dom'

import Home from "./Home"

const App = () => {
  return <Home />
}

document.addEventListener('DOMContentLoaded', () => {
  const rootEl = document.getElementById('app')
  ReactDOM.render(
    <App />, 
    rootEl
    )
})