import React from "react"

export const TodoItem = ({label, status, id, onDelete, updateTodo}: {label: string, status: number, id: number, onDelete: Function, updateTodo: Function}) => {
    return (
        <li className="min-h-20 w-full border border-px border-blue-800 bg-blue-200 my-4 p-2 flex flex-col justify-between shadow-lg">
            <div className="flex w-full justify-between">
                {status !== 1 && <button className="px-2 h-6 bg-green-500 text-white" onClick={() => updateTodo({id: id, status: 1})}>Mark Complete</button>}
                {status !== 0 && <button className="px-2 h-6 bg-gray-600 text-white" onClick={() => updateTodo({id: id, status: 0})}>Revert</button>}
                <button className="px-2 h-6 bg-red-500 text-white" onClick={onDelete}>Delete</button>
            </div>
            <div>{label}</div>
        </li>
    )
}

export default TodoItem