import React, { useState, useEffect } from "react"
import TodoItem from "../TodoItem"
import classNames from "classnames"

const Tab = ({onClick, label, color, selected, unselectClasses}: {onClick: Function, label: string, color, selected: boolean, unselectClasses: string}) => {
    return (
        <div className={classNames(color,"h-full","w-full","text-center", "cursor-pointer", {"shadow-inner": !selected, [unselectClasses]: !selected })} onClick={onClick}>
            {label}
        </div>
    )
}

export default Tab